<div class="dark">
  <div class="login">
    <div class="row login-wrapper">
      <div class="col-md-12 col-lg-6 d-flex justify-content-center">
        <img src="assets/login-logo.svg" alt="" />
      </div>
      <div class="col-md-12 col-lg-6 d-flex justify-content-center">
        <div>
          <div class="dropdown nav-item me-2">
            <a
              class="dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ lang }}
            </a>
            <ul class="dropdown-menu">
              <li>
                <button class="dropdown-item" (click)="setLang('ru')">Ru</button>
              </li>
              <li>
                <button class="dropdown-item" (click)="setLang('by')">By</button>
              </li>
            </ul>
          </div>
          <div class="title">{{ 'login.title' | translate }}</div>
          <form class="form" [formGroup]="loginForm" (ngSubmit)="login()">
            <input
              type="text"
              formControlName="username"
              placeholder="{{ 'login.placeholder-1' | translate }}"
            />
            <input
              type="password"
              formControlName="password"
              placeholder="{{ 'login.placeholder-2' | translate }}"
            />
            <button class="btn enter">{{ 'login.enter' | translate }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
