import { Routes, UrlSegment } from '@angular/router';
import { LandingComponent } from './components/landing/landing.component';
import { ObjectComponent } from './components/object/object.component';
import { ManagementComponent } from './components/management/management.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { LoginComponent } from './components/login/login.component';
import { PaymentComponent } from './components/payment/payment.component';
import { ClientEditComponent } from './components/client-edit/client-edit.component';

export function objectMatcher(url: UrlSegment[]) {
  if (url.length === 2 && url[0].path === 'p') {
    const regex: RegExp = new RegExp('^[0-9a-zA-Z]{5}$');
    if (regex.test(url[1].path)) {
      return { consumed: url };
    }
  }
  return null;
}

export const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'management', component: ManagementComponent },
  { path: 'login', component: LoginComponent },
  { path: 'object/:id/payment/activate', component: PaymentComponent },
  {path: 'p/:id/edit', component: ClientEditComponent},
  { matcher: objectMatcher, component: ObjectComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/404' }
];
