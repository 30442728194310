import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';
import { IsNullPipe } from '../../pipes/is-null.pipe';
import { CommonModule } from '@angular/common';
import { ObjectManagement } from '../../models/object-management';

@Component({
  selector: 'app-pattern-dialog',
  standalone: true,
  imports: [IsNullPipe, CommonModule],
  templateUrl: './pattern-dialog.component.html',
  styleUrl: './pattern-dialog.component.scss',
})
export class PatternDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { message: string; objectData: ObjectManagement }
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  applyAndCloseDialog(): void {
    this.dialogRef.close(true);
  }
}
