<app-payment></app-payment>
<div class="main">
  <app-navbar class="dark"></app-navbar>
  <app-header class="dark" id="main"></app-header>
  <app-package-of-services class="dark" id="packages"></app-package-of-services>
  <app-terms class="light" id="terms"></app-terms>
<!--  <app-review class="dark" id="mass-media"></app-review>-->
  <app-contacts class="dark" id="contacts"></app-contacts>
  <app-footer class="light"></app-footer>
</div>
