import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { LoginService } from '../../services/login.service';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { langSelections } from '../../app.component';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LoginComponent {
  loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });
  langSelections = langSelections;
  lang = '';

  constructor(
    private loginService: LoginService,
    public translateService: TranslateService,
    private cookieService: CookieService,
    private router: Router,
    private notification: MatSnackBar,
    private translate: TranslateService,
  ) {
  }
  ngOnInit(): void {
    const lang = this.cookieService.get('lang');
    this.lang = langSelections.find((l) => l.code === lang)?.value;
    this.translateService.use(lang);
  }

  login() {
    if (this.loginForm.valid) {
      this.loginService
        .login(this.loginForm.value.username, this.loginForm.value.password)
        .subscribe(
          (res) => {
            this.router.navigate(['management']);
          },
          (error) => {
            this.translate
            .get('login.error')
            .subscribe((msg) => {
              this.notification.open(msg, 'OK', { duration: 5000 });
            });
          }
        );
    } else {
                  this.translate
            .get('login.empty-fields')
            .subscribe((msg) => {
              this.notification.open(msg, 'OK', { duration: 5000 });
            });
    }
  }

  setLang(language: string): void {
    this.cookieService.set('lang', language);
    this.lang = langSelections.find((l) => l.code === language)?.value;
    this.translateService.use(language);
  }
}
