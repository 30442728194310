import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from '../../services/payment.service';
import { MatDialog } from '@angular/material/dialog';
import { PaymentResponse } from '../../models/payment-response';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-payment',
  standalone: true,
  imports: [],
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss',
})
export class PaymentComponent implements OnInit {
  imageSrc: any;
  orderId: string;
  paymentData: PaymentResponse;

  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.orderId = params['orderId'];
      if (this.orderId) {
        this.paymentService.sendPaymentResponse(this.orderId).subscribe(
          (response: PaymentResponse) => {
            this.paymentData = response;
            this.openDialog();
          },
          (error: HttpErrorResponse) => {
            // this.paymentData = error.message;
            this.openDialog();
          }
        );
      }
    });
  }

  openDialog() {
    this.dialog.open(ModalComponent, {
      data: this.paymentData,
    });
  }
}
