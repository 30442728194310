import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-package-of-services',
  standalone: true,
  imports: [TranslateModule, CommonModule],
  templateUrl: './package-of-services.component.html',
  styleUrl: './package-of-services.component.scss'
})
export class PackageOfServicesComponent {
  packages = [1,2,3]

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService) {

  }

  goTo(el: string, contentId: string) {
    const message = this.translateService.instant(contentId);
    (<HTMLInputElement> this.document.getElementById("contact.message")).value += '\n\n' + message;
    const id = this.document.getElementById(el);
    if (id) {
      id.scrollIntoView({ behavior: 'smooth' })
    };
  }

}
