import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNull',
  standalone: true
})
export class IsNullPipe implements PipeTransform {

  transform(value: any): any {
    if(value===null || value === 0 || value===undefined || value==='') {
      return "N/A";
    }
    return value;
  }

}


