import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperContainer } from 'swiper/element';
import { Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
import { SafeUrl } from '@angular/platform-browser';
import { ObjectService } from '../../services/object.service';
import { environment } from '../../../environments/environment';
import { MediaContext } from '../../models/media-context';

@Component({
  selector: 'app-object-media',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './object-media.component.html',
  styleUrls: ['./object-media.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ObjectMediaComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() uuid: string;
  mediaUrls: SafeUrl[] = [];
  mediaLinks: string[] = [];

  @ViewChild('swiperContainer', { static: false })
  swiperContainer: ElementRef<SwiperContainer>;

  options: SwiperOptions = {
    modules: [Pagination, Navigation],
    pagination: { clickable: true, el: '.swiper-pagination' },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 30,
    breakpoints: {
      640: { slidesPerView: 1 },
      768: { slidesPerView: 2 },
      1024: { slidesPerView: 3 },
    },
  };

  constructor(private objectService: ObjectService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['uuid'] && changes['uuid'].currentValue) {
      this.loadMediaLinksByUuid();
    }
  }

  loadMediaLinksByUuid() {
    this.mediaUrls = [];
    this.mediaLinks = [];
    this.objectService
      .getImageLinksByUuid(this.uuid)
      .subscribe((links: string[]) => {
        this.mediaLinks = links;
        this.loadMediaUrlsOneByOne(0);
      });
  }

  loadMediaUrlsOneByOne(index: number) {
    if (index >= this.mediaLinks.length) {
      this.updateSwiper(); // Обновляем Swiper после загрузки всех изображений
      return;
    }

    const fullUrl =
      environment.backendUrl.replace('/api', '') + this.mediaLinks[index];
    this.objectService
      .loadMediaByUrl(fullUrl)
      .subscribe((media: MediaContext) => {
        this.mediaUrls.push(media);
        this.updateSwiper();
        this.loadMediaUrlsOneByOne(index + 1);
      });
  }

  ngAfterViewInit(): void {
    this.initializeSwiper();
  }

  private initializeSwiper(): void {
    if (this.swiperContainer && this.swiperContainer.nativeElement) {
      Object.assign(this.swiperContainer.nativeElement, this.options);
      this.swiperContainer.nativeElement.initialize();
    }
  }

  updateSwiper(): void {
    setTimeout(() => {
      if (
        this.swiperContainer &&
        this.swiperContainer.nativeElement &&
        this.swiperContainer.nativeElement.swiper
      ) {
        this.swiperContainer.nativeElement.swiper.update(); // Обновляем Swiper
      }
    });
  }
}
