import { Component, OnInit } from '@angular/core';
import { NavbarComponent } from '../navbar/navbar.component';
import { ManagementTableComponent } from '../management-table/management-table.component';
import { FooterComponent } from '../footer/footer.component';
import { v4 as uuidv4 } from 'uuid';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-management',
  standalone: true,
  imports: [NavbarComponent, ManagementTableComponent, FooterComponent],
  templateUrl: './management.component.html',
  styleUrl: './management.component.scss',
})
export class ManagementComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
