import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  backendUrl = environment.backendUrl;
  loginUrl = this.backendUrl + '/auth';

  constructor(private cookieService: CookieService, private http: HttpClient) {}

  login(username: string, password: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'text/plain', }),
         
      withCredentials: true, 
      observe: 'response' as 'response'
    };
    const url = this.loginUrl + `?username=${username}`;
    return this.http.post<any>(url, password, httpOptions);
  }
}
