import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AngularYandexMapsModule } from 'angular8-yandex-maps';
import { Contact } from '../../models/contact';
import { ContactService } from '../../services/contact.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contacts',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    AngularYandexMapsModule,
  ],
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.scss',
})
export class ContactsComponent implements OnInit {
  placemarkProperties: ymaps.IPlacemarkProperties = {
    hintContent: '',
    balloonContent: '',
  };
  placemarkOptions: ymaps.IPlacemarkOptions = {
    iconLayout: 'default#image',
    iconImageHref: 'assets/placemark.svg',
    iconImageSize: [32, 32],
  };
  latitude = null;
  longitude = null;

  constructor(
    private contactService: ContactService,
    private notification: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.get('main.contacts.latitude').subscribe((msg) => {
      this.latitude = msg;
    });
    this.translate.get('main.contacts.longitude').subscribe((msg) => {
      this.longitude = msg;
    });
  }

  feedbackForm = new FormGroup({
    name: new FormControl('', Validators.required),
    contactVia: new FormControl('Telegram', Validators.required),
    number: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
    agreement: new FormControl(false, Validators.required),
  });

  sendFeedback() {
    if (this.feedbackForm.valid) {
      const contactData: Contact = {
        name: this.feedbackForm.value.name,
        communicationChannel: this.feedbackForm.value.contactVia,
        address: this.feedbackForm.value.number,
        consent: this.feedbackForm.value.agreement,
        text: this.feedbackForm.value.message,
      };
      this.contactService.contact(contactData).subscribe(
        (res) => {
          this.translate
            .get('main.contacts.success-message')
            .subscribe((msg) => {
              this.notification.open(msg, 'OK', { duration: 5000 });
            });
        },
        (error) => {
          this.translate.get('main.contacts.error-message').subscribe((msg) => {
            this.notification.open(msg, 'OK', { duration: 5000 });
          });
        }
      );
    } else {
      this.translate
        .get('main.contacts.empty-fields-message')
        .subscribe((msg) => {
          this.notification.open(msg, 'OK', { duration: 5000 });
        });
    }
  }

  openTelegram() {
    window.open('https://t.me', '_blank');
  }
}
