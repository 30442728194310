import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IsNullPipe } from "../../pipes/is-null.pipe";
import { PaymentResponse } from '../../models/payment-response';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [IsNullPipe],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: PaymentResponse,
    private router: Router
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
    this.router.navigate([]);
  }
}
