import { Component, OnInit } from '@angular/core';
import { NavbarComponent } from '../navbar/navbar.component';
import { ObjectHeaderComponent } from '../object-header/object-header.component';
import { ObjectLinksComponent } from '../object-links/object-links.component';
import { ObjectRelativesComponent } from '../object-relatives/object-relatives.component';
import { ObjectLocationComponent } from '../object-location/object-location.component';
import { FooterComponent } from '../footer/footer.component';
import { ObjectService } from '../../services/object.service';
import { ObjectData } from '../../models/object-data';
import { ObjectTable } from '../../models/object-table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { ObjectMediaComponent } from '../object-media/object-media.component';
import { SafeUrl } from '@angular/platform-browser';
import { MediaContext } from '../../models/media-context';
import { CookieService } from 'ngx-cookie-service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

const tableNames = {
  object: 'объект',
  details: 'подробно',
  links: 'ссылки',
  location: 'расположение',
};

@Component({
  selector: 'app-object',
  standalone: true,
  imports: [
    CommonModule,
    NavbarComponent,
    ObjectHeaderComponent,
    ObjectLinksComponent,
    ObjectRelativesComponent,
    ObjectLocationComponent,
    FooterComponent,
    ObjectMediaComponent,
  ],
  templateUrl: './object.component.html',
  styleUrl: './object.component.scss',
})
export class ObjectComponent implements OnInit {
  objectData: ObjectData;
  objectTables: ObjectTable[] = null;
  headerSectionContext: ObjectTable = null;
  headerDetailsSectionContext: ObjectTable = null;
  linksSectionContext: ObjectTable = null;
  locationSectionContext: ObjectTable = null;
  locationCoordinates: number[] = null;
  objectImageUrls: string[] = [];
  mediaUrls: SafeUrl[] = [];
  uuid: string;
  mainImage: any;
  firstLoading = true;

  constructor(
    private objectService: ObjectService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    const url = this.router.url.split('/');
    const objectId = url[url.length - 1];
    this.loadObject(objectId);

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd && ev.url.includes('p/')) {
        const url = ev.url.split('/');
        const objectId = url[url.length - 1];
        this.loadObject(objectId);
      }
    });

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (objectId && !this.firstLoading) {
        this.loadObjectContext(objectId, event.lang);
      }
      this.firstLoading = false;
    });
  }

  loadObject(uuid: string) {
    this.objectService.getObjectByUuid(uuid).subscribe(
      (object: ObjectData) => {
        this.objectData = object;
        this.uuid = object.uuid;
        this.loadImageList(this.uuid);
        this.locationCoordinates = [object.latitude, object.longitude];
        this.loadObjectContext(uuid);
      },
      (error) => {
        this.router.navigate(['404']);
      }
    );
  }

  loadImageList(uuid: string) {
    this.objectService
      .getImageLinksByUuid(uuid)
      .subscribe((links: string[]) => {
        const mainLinks = links.filter((link) => {
          return link.includes('main');
        });
        const fullUrl =
          environment.backendUrl.replace('/api', '') +
          (mainLinks.length > 0 ? mainLinks[0] : links[0]);
        this.loadMainImage(fullUrl);
      });
  }

  loadObjectContext(uuid: string, language?: string) {
    if (!language) {
      language = this.translateService.currentLang;
    }
    this.objectService.getObjectContextById(uuid, language).subscribe(
      (tables: ObjectTable[]) => {
        this.objectTables = tables;
        this.assignTables(tables);
      },
      (error) => {
        console.error('Something went wrong', error);
      }
    );
  }

  loadMainImage(url: string) {
    this.mainImage = null;
    this.objectService.loadMediaByUrl(url).subscribe((media: MediaContext) => {
      this.mainImage = media.url;
    });
  }

  assignTables(tables: ObjectTable[]): void {
    if (tables) {
      this.headerSectionContext = tables.find(
        (table) =>
          table.tableName.toLowerCase() === tableNames.object.toLowerCase()
      );
      this.headerDetailsSectionContext = tables.find(
        (table) =>
          table.tableName.toLowerCase() === tableNames.details.toLowerCase()
      );
      this.linksSectionContext = tables.find(
        (table) =>
          table.tableName.toLowerCase() === tableNames.links.toLowerCase()
      );
      this.locationSectionContext = tables.find(
        (table) =>
          table.tableName.toLowerCase() === tableNames.location.toLowerCase()
      );
    }
  }
}
