<section>
  <div class="row filters">
    <div class="col-md-12 col-lg-6">
      <div>UUID | Имя, название</div>
      <input
        matInput
        (keyup)="applyFilters($event)"
        placeholder="Поиск"
        #input
      />
    </div>
    <div class="col-md-12 col-lg-3">
      <div>Категория</div>
      <mat-select
        [(value)]="selectedCategory"
        (selectionChange)="applyFilters()"
      >
        <mat-option *ngFor="let cat of categoriesFilter" value="{{ cat }}">{{
          cat
        }}</mat-option>
      </mat-select>
    </div>

    <div class="col-md-12 col-lg-3">
      <div>Язык</div>
      <mat-select
        [(value)]="selectedLanguage"
        (selectionChange)="applyFilters()"
      >
        <mat-option *ngFor="let lang of languages" value="{{ lang }}">{{
          lang
        }}</mat-option>
      </mat-select>
    </div>
  </div>

  <div class="table-container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="uuid">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="sticky-column"
        >
          UUID
        </th>
        <td mat-cell *matCellDef="let row" class="sticky-column">
          {{ row.uuid }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Имя, название</th>
        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
      </ng-container>

      <ng-container matColumnDef="language">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Язык</th>
        <td mat-cell *matCellDef="let row">{{ row.language }}</td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Категория</th>
        <td mat-cell *matCellDef="let row">{{getCategoryName(row.categoryId)}}</td>
      </ng-container>

      <ng-container matColumnDef="public">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Видимость</th>
        <td mat-cell *matCellDef="let row">
          {{ row.published === true ? "Публичный" : "Приватный" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Детали
        </th>
        <td mat-cell *matCellDef="let row">{{ row.description }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Действия</th>
        <td mat-cell *matCellDef="let row">
          <div class="action-container">
            <button 
            (click)="displayEditObjectLink(row)" 
            >
              <img src="assets/management-copy-edit-link-icon.svg" alt="">
            </button>
            <button (click)="displayPaymentLink(row)">
              <img src="assets/management-copy-payment-link-icon.svg" alt="">
            </button>
            <button (click)="sendQR(row)">
              <img src="assets/management-telegram.svg" alt="QR">
            </button>
            <a style="padding: 1px 6px;" [href]="'p/' + row.uuid">
              <img src="assets/view-doc-icon.svg" alt="Документ" />
            </a>
            <button (click)="updateObject(row)">
              <img src="assets/edit-icon.svg" alt="Изменить" />
            </button>

            <button (click)="removeData(row)">
              <img src="assets/delete-icon.svg" alt="Удалить" />
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          Не найдено "{{ input.value }}", <br />
          Категория "{{ selectedCategory }}" <br />
          Язык "{{ selectedLanguage }}"
        </td>
      </tr>
    </table>

    <mat-paginator
      class="sticky-column"
      [pageSizeOptions]="[10, 20, 50, 100]"
      aria-label="Select page of users"
    ></mat-paginator>
  </div>

  <button mat-raised-button (click)="addData()" class="">Добавить</button>
  <!-- <button mat-raised-button class="ms-2" (click)="refreshTable()">Обновить</button> -->
</section>
