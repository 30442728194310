<!-- <div *ngIf="objectTables"> -->
<app-navbar class="dark"></app-navbar>
<app-object-header [objectContext]="headerSectionContext" [objectContextDetails]="headerDetailsSectionContext"
  [uuid]="uuid" [mainImage]="mainImage" class="dark"></app-object-header>
<app-object-media [uuid]="uuid"></app-object-media>
<app-object-links *ngIf="linksSectionContext && linksSectionContext.columns[0].data.length > 0" [linksContext]="linksSectionContext"
  class="light"></app-object-links>
<!-- <app-object-relatives class="dark"></app-object-relatives> -->
<app-object-location [location]="locationCoordinates" [locationContext]="locationSectionContext"
  class="dark"></app-object-location>
<app-footer class="light"></app-footer>
<!-- </div> -->

<!-- <div *ngIf="!objectTables">
  Object not found
</div> -->