<section class="location">
  <h2>{{ "object.location" | translate }}</h2>
  <div class="row">
    <div class="col-md-12 col-lg-7">
      <div
        class="mb-3"
        *ngFor="
          let _ of [].constructor(ctx.getRowCount(locationContext));
          index as i
        "
      >
        <div class="title">
          {{ ctx.findContext(locationContext, "Заголовок", i) }}
        </div>
        <div class="description">
          {{ ctx.findContext(locationContext, "Описание", i) }}
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-5">
      <div id="map" #yamaps>
        <ya-map [center]="location" [zoom]="15">
          <ya-placemark
            [geometry]="location"
            [properties]="placemarkProperties"
            [options]="placemarkOptions"
          ></ya-placemark>
        </ya-map>
      </div>
    </div>
  </div>
</section>
