import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { langSelections } from '../../app.component';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  BehaviorSubject,
  Observable,
  debounceTime,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { ObjectService } from '../../services/object.service';
import { ObjectData } from '../../models/object-data';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    FormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnInit {
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild('searchInput') searchInput: ElementRef;
  langSelections = langSelections;
  lang = '';
  linkId = '#main-link';
  linkIdMobile = '#main-link-mobile';
  currentUrl: string;
  searchMode: boolean = false;
  searchRequest = new FormControl('');
  activeMenu = false;
  objectsData: ObjectData[] = [];
  filteredObjects: Observable<ObjectData[]>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cookieService: CookieService,
    public router: Router,
    private translateService: TranslateService,
    private objectService: ObjectService
  ) {}

  ngOnInit(): void {
    this.applyTranslation();
    this.getAllObjects();
    this.filteredObjects = this.searchRequest.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
    // this.objectsSearching();
  }

  private _filter(value: string): ObjectData[] {
    const filterValue = value.toLowerCase().replace(/\s/g, '');
    if (filterValue !== '') {
      return this.objectsData.filter((object) =>
        object.name.toLowerCase().replace(/\s/g, '').includes(filterValue)
      );
    } else {
      return [];
    }
  }

  setLang(language: string): void {
    this.cookieService.set('lang', language);
    this.lang = langSelections.find((l) => l.code === language)?.value;
    this.translateService.use(language);
  }

  toggleMenu() {
    this.activeMenu = !this.activeMenu;
  }

  goToObject(uuid: string) {
    this.router.navigate(['p', uuid]);
  }

  getAllObjects() {
    this.objectService
      .getAllObjectsData()
      .subscribe((objects: ObjectData[]) => {
        this.objectsData = objects;
      });
  }

  applyTranslation() {
    this.currentUrl = this.router.url;
    const lang = this.cookieService.get('lang');
    this.lang = langSelections.find((l) => l.code === lang)?.value;
    this.translateService.use(lang);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    try {
      const children = this.document.querySelector('.main').children;
      const currentSection = Array.from(children).find((child) => {
        const rect = child.getBoundingClientRect();
        return rect.top >= -10 && rect.top <= window.innerHeight;
      });
      if (currentSection) {
        if (currentSection.tagName === 'APP-NAVBAR') {
          this.linkId = `#main-link`;
          this.linkIdMobile = `#main-link-mobile`;
          this.assignActiveSection();
        } else {
          const id = currentSection.getAttribute('id');
          this.linkId = `#${id}-link`;
          this.linkIdMobile = `#${id}-link-mobile`;
          this.assignActiveSection();
        }
      }
    } catch {}
  }

  assignActiveSection() {
    const active = this.document.querySelector('.active-sec');
    const activeMobile = this.document.querySelector('.active-sec-mobile');
    const newActive = this.document.querySelector(this.linkId);
    const newActiveMobile = this.document.querySelector(this.linkIdMobile);

    if (active !== newActive) {
      active?.classList.remove('active-sec');
      newActive?.classList.add('active-sec');
    }

    if (activeMobile !== newActiveMobile) {
      activeMobile?.classList.remove('active-sec-mobile');
      newActiveMobile?.classList.add('active-sec-mobile');
    }
  }

  goTo(el: string) {
    this.router.navigate(['']);
    const id = this.document.getElementById(el);
    if (id) id.scrollIntoView({ behavior: 'smooth' });
  }

  toggleSearchMode() {
    this.searchMode = !this.searchMode;
  }
}
