<section class="dark">
  <swiper-container #swiperContainer class="swiper">
    <swiper-slide class="mb-5" *ngFor="let media of mediaUrls">
      <div class="d-flex justify-content-center">
        <img *ngIf="media['type'].includes('image')" class="image" [src]="media['url']" alt="Image" />
        <video controls *ngIf="media['type'].includes('video')" class="video" >
            <source [src]="media['url']">
            Your browser does not support the video tag.
        </video>
      </div>
    </swiper-slide>
  </swiper-container>
</section>


