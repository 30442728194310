import { Injectable } from '@angular/core';
import { ObjectTable } from '../models/object-table';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TableContextService {
  constructor(private sanitizer: DomSanitizer) {}

  findContext(
    table: ObjectTable,
    columnName: string,
    dataIndex: number
  ): string | null | SafeHtml {
    if (table) {
      const columnIndex = this.findColumnIndex(table, columnName);
      return table.columns[columnIndex].data[dataIndex];
      
    }
    return null;
  }

  findColumnIndex(table: ObjectTable, columnName: string): number {
    return table.columns.findIndex(
      (col) => col.header.toLowerCase() === columnName.toLowerCase()
    );
  }

  getRowCount(table: ObjectTable): number {
    if (table) {
      let maxRowCount = 0;
      for (const column of table.columns) {
        maxRowCount = Math.max(maxRowCount, column.data.length);
      }
      return maxRowCount;
    }
    return 0;
  }
}
