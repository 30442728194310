import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  toggleTheme(isDark: boolean) {
    const body = this.document.body;
    body.classList.toggle('dark', isDark);
    body.classList.toggle('light', !isDark);
  }
}
