<section class="header">
  <div class="my-container">
    <div class="row">
        <div class="d-lg-none col-12" style="margin-top: 5em;">
            <img class="w-100" src="assets/dark-background-header.png" alt="" />
          </div>
      <div class="col-md-12 col-lg-6 main-block">
        <p>{{ "main.header.subtitle" | translate }}</p>
        <h1>{{ "main.header.title" | translate }}</h1>
        <div class="main-content">
          <p>{{ "main.header.description" | translate }}</p>
          <button (click)="goTo('contacts')">
            {{ "main.header.btn-contact" | translate }}
          </button>
        </div>
      </div>
      <div class="d-none d-lg-block col-6">
        <img class="w-100" src="assets/dark-background-header.png" alt="" />
      </div>
    </div>
  </div>
</section>
