import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as yaml from 'yamljs';

export class YamlTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient, public prefix: string = '/assets/i18n/', public suffix: string = '.yaml') {}

  public getTranslation(lang: string): Observable<any> {
    if (!lang){
      lang = 'ru';
    }
    return this.http.get(`${this.prefix}${lang}${this.suffix}`, { responseType: 'text' })
      .pipe(map((response: string) => yaml.parse(response)));
  }
  
}
