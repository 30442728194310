import { Component, OnInit, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ThemeService } from './services/theme.service';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from './pipes/translate.pipe';
import { CookieService } from 'ngx-cookie-service';
import {TranslateModule } from '@ngx-translate/core';
import Swiper from 'swiper';

export const langSelections: any[] = [
  { imgUrl: 'assets/ru-flag.svg', value: 'Ru', code: 'ru' },
  { imgUrl: 'assets/by-flag.svg', value: 'By', code: 'by' },
];

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    HttpClientModule,
    CommonModule,
    TranslateModule
  ],
  providers: [TranslatePipe],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  langSelections = langSelections;
  title = 'remember-ui';

  constructor(
    public themeService: ThemeService,
    private cookieService: CookieService
  ) {
    themeService.toggleTheme(true);
  }

  ngOnInit(): void {
    const lang = this.cookieService.get('lang');
    if (!lang) this.cookieService.set('lang', langSelections[0].code);
  }
}
