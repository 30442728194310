<div class="dark bg">
    <div class="dialog">
        <div class="content">
            <div class="message">
                {{data.message | isNull}}
            </div>
            <div class="footer-buttons">
                <div class="btn-container">
                    <button (click)="applyAndCloseDialog()" class="footer-btn apply">Подтвердить</button>
                </div>
                <div class="btn-container">
                    <button (click)="closeDialog()" class="footer-btn decline">Отменить</button>
                </div>
            </div>
        </div>
    </div>
</div>