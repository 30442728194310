<div class="dropzone" appDragAndDrop (fileDropped)="onFileDropped($event)">
  <img class="cloud" src="assets/upload-cloud.svg" alt="" />
  <div class="load-field-label">
    Перенесите файл в размеченную область <br />
    или
  </div>
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    multiple
    (change)="fileBrowseHandler($event)"
  />
  <label for="fileDropRef" class="select-file-button">Выберите файл</label>
</div>
<div class="files">
  <div *ngFor="let file of files; let i = index" class="file-item">
      <img
        style="max-height: 100px; max-width: 100px"
        [src]="displayImageOrFileIcon(file)"
        alt=""
      />
      <button (click)="downloadFile(file)">
        {{ file.name ? file.name : getFilenameFromUrl(file) }}
        {{ file.size ? formatBytes(file.size) : "" }}
      </button>
      <button
        *ngIf="router.url.includes('management')"
        class="btn ms-2"
        (click)="deleteFile(i, file)"
      >
        <img src="assets/trash.svg" alt="" />
      </button>
  </div>
</div>
