<section>
  <h2>{{"object.links" | translate}}</h2>
  <div class="links-container">
    <div class="link" *ngFor="let _ of [].constructor(ctx.getRowCount(linksContext)); index as i">
      <h5>{{ ctx.findContext(linksContext, "Заголовок", i) }}</h5>
      <div>
        <a href="{{ctx.findContext(linksContext, 'Ресурс', i)}}">{{ ctx.findContext(linksContext, "Наименование", i) }}</a>
        <p>{{ ctx.findContext(linksContext, "Описание", i) }}</p>
      </div>
    </div>
  </div>
</section>
