import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ObjectTable } from '../../models/object-table';
import { TableContextService } from '../../services/table-context.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-object-links',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './object-links.component.html',
  styleUrl: './object-links.component.scss',
})
export class ObjectLinksComponent implements OnInit {
  @Input() linksContext: ObjectTable;

  constructor(public ctx: TableContextService){}

  ngOnInit(): void {
  }
}
