<div class="dark">
  <h2 *ngIf="data.mode === 'update'" mat-dialog-title>
    Изменить объект {{ data.object.uuid }}
  </h2>
  <h2 *ngIf="data.mode === 'create'" mat-dialog-title>Создать объект</h2>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="objectForm">
      <label>Имя, название</label>
      <input type="text" formControlName="name" />
      <label>Категория</label>
      <select formControlName="categoryId">
        <option *ngFor="let cat of categories" [value]="cat.id">
          {{ cat.name }}
        </option>
      </select>
      <label>Язык</label>
      <select formControlName="language">
        <option *ngFor="let lang of langs" [value]="lang">
          {{
            lang === "ru"
              ? "Русский"
              : lang === "by"
              ? "Белорусский"
              : "Русский и Белорусский"
          }}
        </option>
      </select>
      <label>Опубликовать</label>
      <input
        class="custom-checkbox"
        type="checkbox"
        formControlName="published"
      />
      <label *ngIf="objectForm.value.published">Уровень подписки</label>
      <select *ngIf="objectForm.value.published" formControlName="subscriptionLevel">
        <option *ngFor="let lvl of subscriptionLevels" [value]="lvl.id">
          {{ lvl.name }}
        </option>
      </select>
      <label>Описание</label>
      <textarea formControlName="description"></textarea>
      <label>Информация об оплате</label>
      <input type="text" formControlName="paymentDetails" />
      <label>Платеж подтвержден</label>
      <input
        class="custom-checkbox"
        type="checkbox"
        formControlName="approved"
      />
      <label>Дата окончания срока</label>
      <div class="d-flex">
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="expirationDate"
          readonly="true"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
      <div *ngIf="data.mode === 'update'">
        <hr>
        <div *ngFor="let payment of objectPayments.sort(sortByDate)">
          <div>UUID:</div>
          <div class="mb-2">{{payment.uuid}}</div>
          <div>Номер заказа:</div>
          <div class="mb-2">{{payment.orderNumber}}</div>
          <div>Статус оплаты:</div>
          <div class="mb-2">{{payment.paymentState}}</div>
          <div>Сумма:</div>
          <div class="mb-2">{{payment.amount}}</div>
          <div>Валютный индекс:</div>
          <div class="mb-2">{{payment.currency}}</div>
          <div>Дата:</div>
          <div>{{payment.date | date : "dd MM YYYY, hh:mm a"}}</div>
          <hr>
        </div>
      </div>
      <label>Расположение</label>
      <div id="map">
        <ya-map
          #map
          [center]="center"
          [zoom]="zoom"
          (ready)="onMapReady($event)"
        >
          <ya-placemark
            [geometry]="center"
            [properties]="placemarkProperties"
            [options]="placemarkOptions"
          ></ya-placemark>
        </ya-map>
      </div>
    </form>
    <div class="drag-n-drop">
      <app-drag-n-drop *ngIf="data.object" [files]="files" [uuid]="data.object.uuid"></app-drag-n-drop>
      <app-drag-n-drop *ngIf="!data.object" [files]="files"></app-drag-n-drop>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Отменить</button>
    <button
      *ngIf="data.mode === 'update'"
      mat-button
      (click)="onUpdateConfirm()"
      cdkFocusInitial
    >
      Подтвердить
    </button>
    <button
      *ngIf="data.mode === 'create'"
      mat-button
      (click)="onCreateConfirm()"
      cdkFocusInitial
    >
      Подтвердить
    </button>
  </mat-dialog-actions>
</div>
