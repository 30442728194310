<div class="nav">
  <a href="/">
    <img src="assets/logo.svg" alt="СИЛА ПАМЯТИ" />
  </a>
  <a
    *ngIf="currentUrl === '/'"
    id="main-link"
    (click)="goTo('main')"
    class="my-nav-item d-none d-lg-block active-sec"
    >{{ "main.navbar.main" | translate }}</a
  >
  <a
    *ngIf="currentUrl === '/'"
    id="packages-link"
    (click)="goTo('packages')"
    class="my-nav-item d-none d-lg-block"
    >{{ "main.navbar.packages" | translate }}</a
  >
  <!-- <a
    *ngIf="currentUrl === '/'"
    id="mass-media-link"
    (click)="goTo('mass-media')"
    class="my-nav-item d-none d-lg-block"
    >{{ "main.navbar.mass-media" | translate }}</a
  > -->
  <a
    *ngIf="currentUrl === '/'"
    id="contacts-link"
    (click)="goTo('contacts')"
    class="my-nav-item d-none d-lg-block"
    >{{ "main.navbar.contacts" | translate }}</a
  >
  <div class="d-flex align-items-end">
    <div class="d-flex align-items-center">
      <div *ngIf="searchMode">
        <input
          type="text"
          placeholder="{{ 'main.navbar.search' | translate }}"
          [formControl]="searchRequest"
          [matAutocomplete]="auto"
          class="search-input"
          [class.slide-out]="!searchMode"
        />
        <mat-autocomplete #auto="matAutocomplete">
          @for (object of (filteredObjects | async)?.slice(0, 10); track object)
          {
          <mat-option
            (click)="router.navigate(['p', object.uuid])"
            [value]="object.name"
            >{{ object.name }}</mat-option
          >
          }
        </mat-autocomplete>
      </div>
      <button class="d-none d-lg-block" (click)="toggleSearchMode()">
        <img
          class="search-icon me-2"
          [src]="!searchMode ? 'assets/search-icon.svg' : 'assets/close.svg'"
          alt=""
        />
      </button>
    </div>

    <div class="dropdown my-nav-item me-2">
      <a
        class="dropdown-toggle"
        href="#"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ lang }}
      </a>
      <ul class="dropdown-menu">
        <li>
          <button class="dropdown-item" (click)="setLang('ru')">Ru</button>
        </li>
        <li>
          <button class="dropdown-item" (click)="setLang('by')">By</button>
        </li>
      </ul>
    </div>
    <button
      class="main-page-link"
      (click)="router.navigate([''])"
      *ngIf="currentUrl !== '/'"
    >
      <img src="assets/compass.svg" alt="" />
      <span class="d-none d-lg-block">Nekrolog.by</span>
    </button>
    <div *ngIf="currentUrl === '/'" class="hamburger d-lg-none">
      <button (click)="toggleMenu()" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </div>
</div>

<!-- мобильное меню -->

<div *ngIf="activeMenu" class="mobile-menu" [class.open]="activeMenu">
  <div class="menu-header">
    <img src="assets/logo.svg" alt="" />
    <button (click)="toggleMenu()" class="btn">
      <img src="assets/close.svg" alt="" />
    </button>
  </div>
  <div class="row menu-links">
    <a
      id="main-link-mobile"
      (click)="goTo('main'); toggleMenu()"
      class="col-12 menu-link-item active-sec-mobile"
      >{{ "main.navbar.main" | translate }}</a
    >
    <a
      id="packages-link-mobile"
      (click)="goTo('packages'); toggleMenu()"
      class="col-12 menu-link-item"
      >{{ "main.navbar.packages" | translate }}</a
    >
    <a
      id="mass-media-link-mobile"
      (click)="goTo('mass-media'); toggleMenu()"
      class="col-12 menu-link-item"
      >{{ "main.navbar.mass-media" | translate }}</a
    >
    <a
      id="contacts-link-mobile"
      (click)="goTo('contacts'); toggleMenu()"
      class="col-12 menu-link-item"
      >{{ "main.navbar.contacts" | translate }}</a
    >
  </div>
  <div class="mobile-search">
    <img src="assets/search-icon.svg" alt="search" />

    <input
      type="text"
      placeholder="{{ 'main.navbar.search' | translate }}"
      [formControl]="searchRequest"
      [matAutocomplete]="auto"
      class="search-input m-0"
    />
  </div>
  <mat-autocomplete #auto="matAutocomplete">
    @for (object of (filteredObjects | async)?.slice(0, 10); track object) {
    <mat-option
      (click)="router.navigate(['p', object.uuid])"
      [value]="object.name"
      >{{ object.name }}</mat-option
    >
    }
  </mat-autocomplete>
  <div class="contact-items-mobile">
    <a class="c-item" href="/">
      <img src="assets/compass.svg" alt="" />
      <div>Nekrolog.by</div>
    </a>
    <a class="c-item" href="https://t.me/" target="_blank">
      <img src="assets/contacts-telegram.svg" alt="" />
      <div>{{ "main.contacts.telegram" | translate }}</div>
    </a>
    <a class="c-item" href="viber://chat?number=%2B123456789" target="_blank">
      <img src="assets/contacts-viber.svg" alt="" />
      <div>{{ "main.contacts.viber" | translate }}</div>
    </a>
    <a class="c-item" href="tel:+375293900855">
      <img src="assets/contacts-phone.svg" alt="" />
      <div>{{ "main.contacts.phone" | translate }}</div>
    </a>
    <a class="c-item">
      <img src="assets/contacts-mail.svg" alt="" />
      <div>{{ "main.contacts.mail" | translate }}</div>
    </a>
  </div>
</div>
