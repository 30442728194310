import { Component, ElementRef, inject, OnInit } from '@angular/core';
import { NavbarComponent } from '../navbar/navbar.component';
import { HeaderComponent } from '../header/header.component';
import { PackageOfServicesComponent } from '../package-of-services/package-of-services.component';
import { ContactsComponent } from '../contacts/contacts.component';
import { TermsComponent } from '../terms/terms.component';
import { FooterComponent } from '../footer/footer.component';
import { ReviewComponent } from '../review/review.component';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from '../../services/payment.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ModalComponent } from '../modal/modal.component';
import { PaymentResponse } from '../../models/payment-response';
import { PaymentComponent } from '../payment/payment.component';

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [
    NavbarComponent,
    HeaderComponent,
    PackageOfServicesComponent,
    ContactsComponent,
    TermsComponent,
    ReviewComponent,
    FooterComponent,
    ModalComponent,
    PaymentComponent,
    TranslateModule,
    MatDialogModule,
  ],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss',
})
export class LandingComponent {}
