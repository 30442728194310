import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  constructor(@Inject(DOCUMENT) private document: Document){}

  goTo(el: string) {
    const id = this.document.getElementById(el);
    if (id) id.scrollIntoView({ behavior: 'smooth' });
  }
}
