import { CommonModule, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { ObjectTable } from '../../models/object-table';
import { TableContextService } from '../../services/table-context.service';
import { ObjectService } from '../../services/object.service';
import { environment } from '../../../environments/environment';
import { SwiperOptions } from 'swiper/types';
import { Navigation, Pagination } from 'swiper/modules';

@Component({
  selector: 'app-object-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './object-header.component.html',
  styleUrl: './object-header.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ObjectHeaderComponent implements OnInit {
  @ViewChild('swiperContainer', { static: false })
  swiperContainer: ElementRef<SwiperContainer>;
  @Input() uuid: string;
  @Input() objectContext: ObjectTable;
  @Input() objectContextDetails: ObjectTable;
  @Input() mainImage: any;

  constructor(
    public ctx: TableContextService,
    private objectService: ObjectService
  ) {}

  ngOnInit(): void {
  }
}
