import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ObjectTable } from '../../models/object-table';
import { TableContextService } from '../../services/table-context.service';
import { CommonModule } from '@angular/common';
import { AngularYandexMapsModule } from 'angular8-yandex-maps';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-object-location',
  standalone: true,
  imports: [CommonModule, AngularYandexMapsModule, TranslateModule],
  templateUrl: './object-location.component.html',
  styleUrl: './object-location.component.scss',
})
export class ObjectLocationComponent implements OnInit {
  @Input() locationContext: ObjectTable;
  @Input() location: number[] = [];

  placemarkProperties: ymaps.IPlacemarkProperties = {
    hintContent: '',
    balloonContent: '',
  };
  placemarkOptions: ymaps.IPlacemarkOptions = {
    iconLayout: 'default#image',
    iconImageHref: 'assets/placemark.svg',
    iconImageSize: [32, 32],
  };

  constructor(public ctx: TableContextService) {}

  ngOnInit(): void {
  }
}
