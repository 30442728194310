import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule, DOCUMENT } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ObjectData } from '../../models/object-data';
import { MatDialog } from '@angular/material/dialog';
import {
  CATEGORIES,
  ManagementDialogComponent,
} from '../management-dialog/management-dialog.component';
import { ManagementService } from '../../services/management.service';
import { ObjectService } from '../../services/object.service';
import { Router } from '@angular/router';
import { ObjectManagement } from '../../models/object-management';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { PaymentService } from '../../services/payment.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Clipboard } from '@angular/cdk/clipboard';
import { ModalComponent } from '../modal/modal.component';
import { PatternDialogComponent } from '../pattern-dialog/pattern-dialog.component';

@Component({
  selector: 'app-management-table',
  styleUrl: './management-table.component.scss',
  templateUrl: './management-table.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
  ],
})
export class ManagementTableComponent implements OnInit, AfterViewInit {
  objects: ObjectManagement[] = [];
  filteredObjects: ObjectManagement[] = [];
  frontendUrl = environment.frontendUrl;

  displayedColumns: string[] = [
    'uuid',
    'name',
    'language',
    'category',
    'public',
    'description',
    'action',
  ];

  categories = CATEGORIES;

  categoriesFilter: (string | number)[] = ['Все', 1, 2, 3];
  mode: string[] = ['Публичный', 'Приватный'];
  languages: string[] = ['Все', 'ru', 'by'];

  searchValue: string = '';

  selectedCategory: string | number = this.categoriesFilter[0];
  selectedLanguage: string = this.languages[0];

  dataSource: MatTableDataSource<ObjectManagement>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;

  constructor(
    public dialog: MatDialog,
    private managementService: ManagementService,
    private objectService: ObjectService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private notification: MatSnackBar,
    private paymentService: PaymentService,
    private clipboard: Clipboard
  ) {
    this.getObjects();
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.filteredObjects);
    this.refreshTable();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.applyFilters();
    this.refreshTable();
  }

  getObjects() {
    this.managementService.getAllObjects().subscribe(
      (objects: ObjectManagement[]) => {
        this.updateObjectsAndFilteredObjects(objects);
      },
      (error) => {
        this.router.navigate(['login']);
      }
    );
  }

  refreshTable() {
    this.managementService
      .getAllObjects()
      .subscribe((objects: ObjectManagement[]) => {
        this.updateObjectsAndFilteredObjects(objects);
      });
  }

  updateObjectsAndFilteredObjects(objects: ObjectManagement[]) {
    this.objects.splice(0, this.objects.length);
    this.filteredObjects.splice(0, this.filteredObjects.length);
    this.objects.push(...objects);
    this.filteredObjects.push(...objects);

    this.dataSource.data = this.filteredObjects;
  }

  applyCategoryFilter() {
    const users: ObjectManagement[] = this.objects.filter(
      (user) => user.categoryId == this.selectedCategory
    );
    this.filteredObjects.splice(0, this.filteredObjects.length);
    this.filteredObjects.push(...users);
  }

  applyLanguageFilter() {
    this.filteredObjects = this.filteredObjects.filter((object) => {
      return object.language === this.selectedLanguage;
    });
  }

  applyFilters(event?: Event) {
    this.filteredObjects.splice(0, this.filteredObjects.length);
    this.filteredObjects.push(...this.objects);

    if (this.selectedCategory !== this.categoriesFilter[0]) {
      this.applyCategoryFilter();
    }

    if (this.selectedLanguage !== this.languages[0]) {
      this.applyLanguageFilter();
    }

    if (this.input.nativeElement.value !== '') {
      this.dataSource.filter = this.input.nativeElement.value
        .trim()
        .toLowerCase();
    }
    if (event) {
      this.searchValue = (event.target as HTMLInputElement).value;
    }
    this.dataSource.filter = this.searchValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateObject(objectData: ObjectManagement) {
    const dialogRef = this.dialog.open(ManagementDialogComponent, {
      data: { object: objectData, mode: 'update' },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.managementService
          .updateObject(result.object, objectData.uuid)
          .subscribe((object: ObjectManagement) => {
            this.refreshTable();
            for (let file of result.files) {
              if (typeof file !== 'string') {
                this.managementService
                  .uploadFiles(file, object.uuid)
                  .subscribe((filesResult) => {});
              }
            }
          });
      }
    });
  }

  addData() {
    const dialogRef = this.dialog.open(ManagementDialogComponent, {
      data: { object: null, mode: 'create' },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.managementService
          .createObject(result.object)
          .subscribe((object: ObjectManagement) => {
            this.refreshTable();
            for (let file of result.files) {
              this.managementService
                .uploadFiles(file, object.uuid)
                .subscribe((filesResult) => {});
            }
          });
      }
    });
  }

  removeData(row: ObjectManagement) {
    const objectData = row;
    const message = `Вы действительно хотите удалить объект "${row.name}" ${row.uuid}`;
    const dialogRef = this.dialog.open(PatternDialogComponent, {
      data: { message, objectData },
    });
    dialogRef.afterClosed().subscribe((confirmation: any) => {
      if (confirmation) {
        this.managementService.deleteObject(row).subscribe((res) => {
          this.refreshTable();
          this.notification.open(`Запись ${row.name} удалена`, 'OK', {
            duration: 5000,
          });
        });
      }
    });
  }

  sendQR(object: ObjectManagement) {
    const data = {
      name: object.name,
      objectUuid: object.uuid,
      url: `${this.document.location.protocol}//${this.document.location.hostname}/p/${object.uuid}`,
    };
    this.objectService.sendQrCode(data).subscribe((res) => {});
  }

  navigateToObject(event: MouseEvent, object: ObjectManagement) {
    const link = `${this.frontendUrl}/p/${object.uuid}`;
    if (event.ctrlKey || event.button === 1) {
      window.open(link, '_blank');
    } else {
      this.router.navigate(['p', object.uuid]);
    }
  }

  getCategoryName(categoryId: number): string {
    const category = CATEGORIES.find((c) => c.id === categoryId);
    return category ? category.name : 'N/A';
  }

  displayEditObjectLink(object: ObjectManagement) {
    this.managementService.generateTemporaryAccess(object).subscribe(
      (res) => {
        const link = `${this.frontendUrl}/p/${object.uuid}/edit`;
        this.clipboard.copy(link);
        this.notification.open(
          `Ссылка: ${link} скопирована в буфер обмена!`,
          'OK'
        );

        // Проверка нажатия Ctrl + Click
        // if (event.ctrlKey) {
        //   window.open(link, '_blank');
        // }
      },
      (error: HttpErrorResponse) => {
        if (object.published) {
          this.notification.open(
            `Невозможно сгенерировать ссылку на объект, поскольку объект уже опубликован`,
            'OK'
          );
        } else {
          this.notification.open(`Произошла ошибка!`, 'OK');
        }
      }
    );
  }

  displayPaymentLink(object: ObjectManagement) {
    this.paymentService.getPaymentLink(object.uuid).subscribe(
      (link: string) => {
        this.clipboard.copy(link);
        this.notification.open(
          `Ссылка ${link} скопирована в буфер обмена!`,
          'OK'
        );
      },
      (error: HttpErrorResponse) => {
        this.notification.open('Произошла ошибка', 'OK');
      }
    );
  }
}
