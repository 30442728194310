<div class="terms">
  <h2>{{ "main.terms.title" | translate }}</h2>
  <div class="row terms-block">
    <div class="col-md-12 col-lg-6">
      <div
        class="row terms-item"
        *ngFor="let _ of [].constructor(stepIndexes); index as i"
      >
        <img
          class="col-md-12 col-lg-2"
          [src]="'assets/terms-icon-' + (i + 1) + '.svg'"
          alt=""
        />
        <div class="terms-item-text col-md-12 col-lg-10">
          <h5>
            {{ "main.terms.steps.step-" + (i + 1) + ".title" | translate }}
          </h5>
          <p>
            {{
              "main.terms.steps.step-" + (i + 1) + ".description" | translate
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-4 sub-sec-1">
      <div class="sub-title">
        {{ "main.terms.subtitle" | translate }}
      </div>
      <img src="assets/terms-img.png" alt="" />
    </div>
  </div>
</div>
