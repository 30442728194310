<div class="sec-container">
  <h2>{{ "main.packages.title" | translate }}</h2>
  <div class="packages">
    <div class="package-item" *ngFor="let i of packages">
      <div class="title">
        <h3>{{ "main.packages.package-" + i + ".title" | translate }}</h3>
        <div class="options">
        <p>
          {{ "main.packages.package-" + i + ".option-1" | translate }}
          <span>{{
            "main.packages.package-" + i + ".value-1" | translate
          }}</span>
        </p>
        <p>
          {{ "main.packages.package-" + i + ".option-2" | translate }}
          <span>{{
            "main.packages.package-" + i + ".value-2" | translate
          }}</span>
        </p>
        <p>
          {{ "main.packages.package-" + i + ".option-3" | translate }}
          <span>{{
            "main.packages.package-" + i + ".value-3" | translate
          }}</span>
        </p>
      </div>
        <div class="mt-3"
          [ngClass]="{
            'option-disabled': i === 1,
            'option-enabled': i === 2 || i === 3
          }"
        >
          <img [src]="i===1 ? 'assets/cross.svg':(i===2 ? 'assets/tick.svg' : 'assets/tick.svg')" alt="" />
          <p>{{ "main.packages.common-option-1" | translate }}</p>
        </div>
        <div class="option-enabled">
          <img src="assets/tick.svg" alt="" />
          <p>{{ "main.packages.common-option-2" | translate }}</p>
        </div>
        <div
        [ngClass]="{
          'option-disabled': i === 1 || i===2,
          'option-enabled': i === 3
        }">
          <img [src]="i===1 || i===2 ? 'assets/cross.svg' : 'assets/tick.svg'" alt="" />
          <p>{{ "main.packages.common-option-3" | translate }}</p>
        </div>
        <div class="item-footer">
          <!-- <div class="test">qwe</div> -->
        <div class="costs">
        <div class="line"></div>
          <div class="general-cost">
            {{ "main.packages.costs.for-3-years" | translate }}
            <span>{{
              "main.packages.costs.for-3-years-cost-"+i | translate
            }}</span>
          </div>
          <div>{{ "main.packages.costs.renewal" | translate }}</div>
          <ul class="renewal">
            <li>
              {{ "main.packages.costs.1-year" | translate }}
              <span>{{ "main.packages.costs.1-year-cost" | translate }}</span>
            </li>
            <li>
              {{ "main.packages.costs.2-years" | translate }}
              <span>{{ "main.packages.costs.2-years-cost" | translate }}</span>
            </li>
            <li>
              {{ "main.packages.costs.3-years" | translate }}
              <span>{{ "main.packages.costs.3-years-cost" | translate }}</span>
            </li>
          </ul>
        </div>
        <div class="btns">
          <a href='{{"main.packages.package-"+i+".example" | translate }}' class="btn-1">{{"main.packages.button-examples" | translate }}</a>
          <button (click)="goTo('contacts', 'main.packages.package-' + i + '.title')" class="btn-2">{{"main.packages.button-order" | translate }}</button>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
