<div class="footer">
  <div class="row">
    <div class="col-md-12 col-lg-2">
      <img style="margin-bottom: 12px" src="assets/footer-logo.svg" alt="" />
      <p class="w-100">{{ "main.footer.description" | translate }}</p>
    </div>
    <div class="col-lg-2 logos-container">
      <div *ngFor="let _ of [].constructor(10); let i = index" class="payment-logo">
        <img [src]="'assets/payment-logos/'+i + '.png'" alt="payment logo">
      </div>
    </div>
    <div class="col-md-12 col-lg-8 section-links">
      <div>
        <button class="footer-item" (click)="goTo('about')">
          {{ "main.footer.about" | translate }}
        </button>
      </div>
      <div>
        <button class="footer-item" (click)="goTo('packages')">
          {{ "main.footer.packages" | translate }}
        </button>
      </div>
      <div>
        <button class="footer-item" (click)="goTo('contacts')">
          {{ "main.footer.contacts" | translate }}
        </button>
      </div>
      <div class="soc-media">
        <div class="footer-item">
          {{ "main.footer.social-media" | translate }}
        </div>
        <div class="d-flex">
          <a class="me-3" href="{{'main.contacts.facebookAddress' | translate}}">
            <img src="assets/facebook-icon.svg" alt="Facebook" />
          </a>
          <a class="me-3" href="{{'main.contacts.instagramAddress' | translate}}">
            <img src="assets/instagram-icon.svg" alt="Instagram" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
