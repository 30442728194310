<section>

  <div class="row">
    <div class="col-md-12 col-lg-3 d-flex justify-content-center">
      <div>
        <img style="border-radius: 16px;" [src]="mainImage" alt="">
      </div>
    </div>
    <div class="col-md-12 col-lg-9">
      <h1 class="title" [innerHTML]="ctx.findContext(objectContext, 'Имя, название', 0)"></h1>
      <h2 class="years" *ngIf="objectContext">{{ ctx.findContext(objectContext, "Дата 1", 0) }} - {{ ctx.findContext(objectContext, "Дата 2", 0) }}</h2>
      <div
        class="details"
        *ngFor="
          let _ of [].constructor(ctx.getRowCount(objectContextDetails));
          index as i
        "
      >
        <!-- <div class="details-title">
          {{ ctx.findContext(objectContextDetails, "Заголовок", i) }}
        </div> -->
        <!-- <div class="details-subtitle">
          {{ ctx.findContext(objectContextDetails, "Подзаголовок", i) }}
        </div> -->
        <div class="details-description" [innerHTML]="ctx.findContext(objectContextDetails, 'Описание', i)">
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section>
  <div class="row object-header">
    <div class="col-3">
      <img class="object-img" src="assets/object-portrait.jpg" alt="">
    </div>
    <div class="col-8 content">
      <h1>{{ ctx.findContext(objectContext, "Имя, название", 0) }}</h1>
      <h2>{{ ctx.findContext(objectContext, "Год начала", 0) }}-{{ ctx.findContext(objectContext, "Год конца", 0) }}</h2>
      <p>
        {{ ctx.findContext(objectContext, "Описание", 0) }}
      </p>
    </div>
    <div class="col-1 contact-links">
      <a href="#">
        <img src="assets/object-facebook-icon.svg" alt="" />
      </a>
      <a href="#">
        <img src="assets/object-ok-icon.svg" alt="" />
      </a>
      <a href="#">
        <img src="assets/object-instagram-icon.svg" alt="" />
      </a>
      <a href="#">
        <img src="assets/object-in-icon.svg" alt="" />
      </a>
      <a href="#">
        <img src="assets/object-twitter-icon.svg" alt="" />
      </a>
    </div>
  </div>

  <div class="row">
    <div class="col-3">
      <swiper-container #swiper
      pagination="true"
      pagination-clickable="true"
      css-mode="true"
      autoplay-delay="2500"
      autoplay-disable-on-interaction="true"
      space-between="30"
      >
      <swiper-slide *ngFor="let img of imgs">
        <img class="slide" [src]="img" alt="photo" />
        <div class="slide-margin"></div>
      </swiper-slide>
    </swiper-container>
    </div>
    <div class="col-9">
      <h1>{{ ctx.findContext(objectContextDetails, "Заголовок", 0) }}</h1>
      <p>{{ ctx.findContext(objectContextDetails, "Описание", 0) }}</p>

    </div>
  </div>
</section> -->
