<div class="section">
  <h2>{{ "main.contacts.title" | translate }}</h2>

  <div class="row">
    <div class="col-md-12 col-lg-5">
      <div class="desc">
        <p>{{ "main.contacts.subtitle" | translate }}</p>
      </div>
      <div id="map" #yamaps>
        <ya-map [center]="[latitude, longitude]" [zoom]="15">
          <ya-placemark
            [geometry]="[latitude, longitude]"
            [properties]="placemarkProperties"
            [options]="placemarkOptions"
          ></ya-placemark>
        </ya-map>
      </div>
    </div>
    <div class="col-md-12 col-lg-7">
      <div class="contact-items">
        <a class="c-item" href="{{'main.contacts.telegramAddress' | translate}}" target="_blank">
          <img src="assets/contacts-telegram.svg" alt="" />
          <div>{{'main.contacts.telegram' | translate}}</div>
        </a>
        <a class="c-item" href="tel:{{'main.contacts.phone' | translate}}">
          <img src="assets/contacts-phone.svg" alt="" />
          <div>{{'main.contacts.phone' | translate}}</div>
        </a>
        <a class="c-item" href="mailto:{{'main.contacts.mailAddress' | translate}}">
          <img src="assets/contacts-mail.svg" alt="" />
          <div>{{'main.contacts.mail' | translate}}</div>
        </a>
      </div>

      <form
        class="feedback-form"
        [formGroup]="feedbackForm"
        (ngSubmit)="sendFeedback()"
      >
        <input
          type="text"
          formControlName="name"
          placeholder="{{ 'main.contacts.placeholder-name' | translate }}"
        />
        <div class="contact-via">
          <select formControlName="contactVia">
            <option value="Telegram">Telegram</option>
            <option value="WhatsApp">WhatsApp</option>
            <option value="Viber">Viber</option>
          </select>
          <input
            class="contact-via-input"
            type="tel"
            formControlName="number"
            placeholder="{{ 'main.contacts.placeholder-contact' | translate }}"
          />
        </div>
        <textarea
          id="contact.message"
          style="resize: none; height: 200px"
          formControlName="message"
          placeholder="{{ 'main.contacts.placeholder-text' | translate }}"
        ></textarea>
        <div class="agreement-checkbox">
          <input type="checkbox" id="agreement" formControlName="agreement" />
          <label for="agreement"
            >{{'main.contacts.confirmation1' | translate}} <a href="{{'main.contacts.docUrl' | translate}}" download>{{'main.contacts.confirmation2' | translate}}</a></label
          >
        </div>
        <div class="send-container">
          <button class="send-btn">
            {{ "main.contacts.button-send" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="mt-3" style="color: rgb(68, 68, 68);">{{'main.contacts.copyright' | translate}}</div>
</div>
