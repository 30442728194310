import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ObjectService } from '../../services/object.service';
import { Router } from '@angular/router';
import { ObjectTable } from '../../models/object-table';
import { NavbarComponent } from '../navbar/navbar.component';
import { FooterComponent } from '../footer/footer.component';
import {
  AngularYandexMapsModule,
  YaMapComponent,
  YaReadyEvent,
} from 'angular8-yandex-maps';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DragNDropComponent } from '../drag-n-drop/drag-n-drop.component';
import { ManagementService } from '../../services/management.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ObjectData } from '../../models/object-data';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { QuillEditorComponent } from 'ngx-quill';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-client-edit',
  standalone: true,
  imports: [
    NavbarComponent,
    FooterComponent,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    TranslateModule,
    MatInputModule,
    MatDatepickerModule,
    AngularYandexMapsModule,
    DragNDropComponent,
    TranslateModule,
    MatProgressSpinnerModule,
    QuillEditorComponent,
  ],
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss'],
})
export class ClientEditComponent implements OnInit {
  objectData: ObjectData;
  uuid: string = '';
  receivedTables: ObjectTable[] = [];
  objectFiles: any[] = [];
  langs: string[] = ['ru', 'by'];
  getlanguageContext: boolean = false;
  availableToUpdate: boolean = true;

  // Markdown properties
  editorForm: FormGroup = this.fb.group({
    html: new FormControl(''),
  })

  //map properies
  coordinates: number[] = [53.90374, 27.551823];
  placemarkProperties: ymaps.IPlacemarkProperties = {
    hintContent: 'Координаты',
    balloonContent: this.coordinates.toString(),
  };
  placemarkOptions: ymaps.IPlacemarkOptions = {
    iconLayout: 'default#image',
    iconImageHref: 'assets/placemark.svg',
    iconImageSize: [32, 32],
  };
  zoom: number = 15;
  mapInstance: any;
  @ViewChild('map') map: YaMapComponent;

  objectForm: FormGroup = new FormGroup({
    languageControl: new FormControl(this.langs[0], Validators.required),
    objectHeader: new FormGroup({
      name: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      descriptionHtml: new FormControl('', Validators.required),
    }),
    objectLinks: new FormArray([]),
    objectLocation: new FormGroup({
      title: new FormControl(''),
      description: new FormControl(''),
    }),
  });

  get objectLinksArray(): FormArray {
    return this.objectForm.get('objectLinks') as FormArray;
  }

  get objectHeader(): FormGroup {
    return this.objectForm.get('objectHeader') as FormGroup;
  }

  get objectLocation(): FormGroup {
    return this.objectForm.get('objectLocation') as FormGroup;
  }

  constructor(
    private objectService: ObjectService,
    private router: Router,
    private notification: MatSnackBar,
    private managementService: ManagementService,
    private sanitizer: DomSanitizer, private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.uuid = this.router.url.split('/')[2];
    this.loadObjectFiles();
    this.getObject();
    const lang = this.objectForm.value.languageControl;
    this.changeObjectContextByLanguage(lang);
    this.onlanguageChange();
  }

  byPassHTML(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }

  private onlanguageChange(): void {
    this.getlanguageContext = true;
    this.objectForm.get('languageControl')?.valueChanges.subscribe((lang) => {
      this.changeObjectContextByLanguage(lang);
    });
  }

  private changeObjectContextByLanguage(lang: string): void {
    this.objectService.getObjectContextById(this.uuid, lang).subscribe(
      (tables: ObjectTable[]) => {
        this.getlanguageContext = false;
        this.receivedTables = tables;
        this.objectLinksArray.controls = [];
        this.initializeHeader();
        this.initializeLinks();
        this.initializeLocation();
      },
      (error) => {
        this.getlanguageContext = false;
        this.receivedTables = [];
        this.objectLinksArray.controls = [];
        this.initializeHeader();
        this.initializeLinks();
        this.initializeLocation();
      }
    );
  }

  private getObject(): void {
    this.objectService.getObjectByUuid(this.uuid).subscribe(
      (object: ObjectData) => {
        this.objectData = object;
        console.log('on getObject', object.latitude, object.longitude);
        this.setLocation(object.latitude, object.longitude);
        if (this.objectData.published) {
          this.router.navigate(['404']);
        }
      },
      (error: HttpErrorResponse) => {
        this.router.navigate(['404']);
      }
    );
  }

  private setLocation(latitude: number, longitude: number): void {
    this.coordinates = [latitude, longitude];
    console.log('on setLocation', this.coordinates);

  }

  private loadObjectFiles(): void {
    this.managementService.getObjectFiles(this.uuid).subscribe((files) => {
      this.objectFiles = files;
    });
  }

  private initializeHeader(): void {
    const parseDate = (dateString: string): Date => {
      const [day, month, year] = dateString.split('.').map(Number);
      return new Date(year, month - 1, day);
    };

    this.objectHeader.patchValue({
      name: this.findData('Объект', 'Имя, название'),
      startDate: parseDate(this.findData('Объект', 'Дата 1')),
      endDate: parseDate(this.findData('Объект', 'Дата 2')),
      title: this.findData('Подробно', 'Заголовок'),
      descriptionHtml: this.findData('Подробно', 'Описание'),
    });
  }

  private initializeLinks(): void {
    const links = this.receivedTables.find(
      (table) => table.tableName === 'Ссылки'
    );
    if (links) {
      const titles = links.columns.find(
        (col) => col.header === 'Заголовок'
      ).data;
      const names = links.columns.find(
        (col) => col.header === 'Наименование'
      ).data;
      const sources = links.columns.find((col) => col.header === 'Ресурс').data;
      const descriptions = links.columns.find(
        (col) => col.header === 'Описание'
      ).data;

      titles.forEach((title, index) => {
        this.addLinksSection(
          title,
          names[index],
          sources[index],
          descriptions[index]
        );
      });
    } else {
      this.addLinksSection();
    }
  }

  private initializeLocation(): void {
    this.objectLocation.patchValue({
      title: this.findData('Расположение', 'Заголовок'),
      description: this.findData('Расположение', 'Описание'),
    });
  }

  private updateContext(formValue: any): ObjectTable[] {
    const formattedTables: ObjectTable[] = [
      {
        tableName: 'Объект',
        columns: [
          {
            header: 'Имя, название',
            data: [formValue.objectHeader.name],
          },
          {
            header: 'Дата 1',
            data: [formValue.objectHeader.startDate.toLocaleDateString()],
          },
          {
            header: 'Дата 2',
            data: [formValue.objectHeader.endDate.toLocaleDateString()],
          },
        ],
      },
      {
        tableName: 'Подробно',
        columns: [
          {
            header: 'Заголовок',
            data: [formValue.objectHeader.title],
          },
          {
            header: 'Описание',
            data: [formValue.objectHeader.descriptionHtml],
          },
        ],
      },
      {
        tableName: 'Ссылки',
        columns: [
          {
            header: 'Заголовок',
            data: formValue.objectLinks.map((link: any) => link.title),
          },
          {
            header: 'Наименование',
            data: formValue.objectLinks.map((link: any) => link.linkName),
          },
          {
            header: 'Ресурс',
            data: formValue.objectLinks.map((link: any) => link.linkSource),
          },
          {
            header: 'Описание',
            data: formValue.objectLinks.map((link: any) => link.description),
          },
        ],
      },
      {
        tableName: 'Расположение',
        columns: [
          {
            header: 'Заголовок',
            data: [formValue.objectLocation.title, 'Координаты:'],
          },
          {
            header: 'Описание',
            data: [
              formValue.objectLocation.description,
              this.coordinates.toString(),
            ],
          },
        ],
      },
    ];
    return formattedTables;
  }

  private updateObject(formValue: any): ObjectData {
    let object = this.objectData;
    object.latitude = this.coordinates[0];
    object.longitude = this.coordinates[1];
    this.objectData = object;
    return object;
  }

  private uploadFiles(): void {
    for (let file of this.objectFiles) {
      if (typeof file !== 'string') {
        this.managementService.uploadFiles(file, this.uuid).subscribe(
          (filesResult) => {},
          (error: HttpErrorResponse) => {
            this.notification.open(
              `Ошибка обновления медиа файлов ${file.name}`,
              'OK',
              {
                duration: 5000,
              }
            );
          }
        );
      }
    }
  }

  private uploadObjectDataAndContext(
    formattedOutput: ObjectTable[],
    language: string,
    object: ObjectData
  ): void {
    this.objectService
      .uploadObjectDataAndContext(this.uuid, formattedOutput, language, object)
      .subscribe(
        (res) => {
          this.notification.open('Изменения сохранены!', 'OK', {
            duration: 5000,
          });
        },
        (error: HttpErrorResponse) => {
          this.notification.open(
            `Произошла ошибка: ${
              error.status === 500
                ? 'Вы не можете изменить объект, пока он опубликован и доступен в общей сети'
                : error.status
            }`,
            'OK',
            {
              duration: 5000,
            }
          );
        }
      );
  }

  private findData(
    tableName: string,
    header: string,
    dataIndex: number = 0
  ): string | null {
    const table = this.receivedTables.find(
      (table) => table.tableName.toLowerCase() === tableName.toLowerCase()
    );
    if (!table) {
      console.error(`Таблица с именем ${tableName} не найдена`);
      return null;
    }
    const column = table.columns.find(
      (c) => c.header.toLowerCase() === header.toLowerCase()
    );
    if (!column) {
      console.error(
        `Колонка с заголовком ${header} не найдена в таблице ${tableName}`
      );
      return null;
    }
    return column.data[dataIndex] || null;
  }

  private onMapClick(event: any): void {
    const coords = event.get('coords');
    console.log('onMapClick1', coords);
    this.coordinates = [coords[0], coords[1]];
    console.log('onMapClick2', this.coordinates);
    this.updatePlacemark();
  }

  private updatePlacemark(): void {
    if (this.mapInstance) {
      this.mapInstance.geoObjects.removeAll(); // Удаляем все объекты с карты
      const placemark = new (window as any).ymaps.Placemark(
        this.coordinates,
        {
          hintContent: 'Координаты',
          balloonContent: this.coordinates.toString(),
        },
        this.placemarkOptions
      );
      this.mapInstance.geoObjects.add(placemark);
      console.log('on updatePlacemark', placemark)
    }
  }

  // HTML methods
  public updateObjectAndContextButton(): void {
    if (this.objectForm.valid) {
      const formValue = this.objectForm.value;
      const object: ObjectData = this.updateObject(formValue);
      const formattedOutput: ObjectTable[] = this.updateContext(formValue);
      const language = this.objectForm.value.languageControl;
      this.uploadObjectDataAndContext(formattedOutput, language, object);
      this.uploadFiles();
    } else {
      this.notification.open('Заполните все поля', 'OK', { duration: 5000 });
    }
  }

  public addLinksSection(
    title: string = '',
    linkName: string = '',
    linkSource: string = '',
    description: string = ''
  ): void {
    const linkGroup = new FormGroup({
      title: new FormControl(title, Validators.required),
      linkName: new FormControl(linkName, Validators.required),
      linkSource: new FormControl(linkSource, Validators.required),
      description: new FormControl(description),
    });
    this.objectLinksArray.push(linkGroup);
  }

  public removeLinksSection(index: number) {
    this.objectLinksArray.removeAt(index);
  }

  public onMapReady(event: YaReadyEvent): void {
    this.mapInstance = event.target;
    this.mapInstance.events.add('click', (e: any) => {
      this.onMapClick(e);
    });
  }
}
