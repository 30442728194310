import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaymentResponse } from '../models/payment-response';
import { CompletedPayment } from '../models/completed-payment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  backendUrl = environment.backendUrl;
  objectUrl = this.backendUrl + '/object';
  paymentUrl = this.objectUrl + '/payment/activate';

  constructor(private http: HttpClient) {}

  sendPaymentResponse(orderId: string): Observable<PaymentResponse> {
    const params: HttpParams = new HttpParams().set('orderId', orderId);
    return this.http.post<PaymentResponse>(`${this.paymentUrl}`, null, { params: params });
  }


  getObjectPayments(uuid: string) : Observable<CompletedPayment[]> {
    return this.http.get<CompletedPayment[]>(`${this.objectUrl}/${uuid}/payments`);
  }

  getPaymentLink(
    uuid: string,
    amount: number = 1,
    currency: number = 933,
    subLevel: number = 1
  ): Observable<string> {
    const params: HttpParams = new HttpParams()
      .set('amount', amount)
      .set('currency', currency)
      .set('subLevel', subLevel);
    return this.http.post(`${this.objectUrl}/${uuid}/payment`, null, {
      params: params,
      responseType: 'text',
    });
  }
}
