<div class="bg dark">
  <div class="dialog">
    <div class="content row">
      <div class="dialog-header">
        <div class="title">ID объекта: {{ data.uuid | isNull }}</div>
        <div class="description">
          Номер заказа: {{ data.orderNumber | isNull }}
          <br />
          Статус: {{ data.paymentState | isNull }}
          <br />
          Оплачено: {{ data.amount / 100 | isNull }}
          <br />
          Валютный индекс: {{ data.currency | isNull }}
          <br />
          Дата: {{ data.date | isNull }}
        </div>
      </div>
      <div class="dialog-footer">
        <button class="btn apply" (click)="closeDialog()">Подтвердить</button>
      </div>
    </div>
  </div>
</div>
