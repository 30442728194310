import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Contact } from '../models/contact';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  backendUrl = environment.backendUrl;
  contactUrl = this.backendUrl + '/contact';

  constructor(private http: HttpClient) {}

  contact(filledForm: Contact) {
    return this.http.post(this.contactUrl, filledForm);
  }
}
