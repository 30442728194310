import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [TranslateModule, CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  
  constructor(@Inject(DOCUMENT) private document: Document, private router: Router){}

  goTo(el: string) {
    this.router.navigate([""]);
    const id = this.document.getElementById(el);
    if (id) id.scrollIntoView({ behavior: 'smooth' });
  }
}
