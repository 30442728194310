import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [TranslateModule, CommonModule],
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.scss'
})
export class TermsComponent implements OnInit {

  stepIndexes: number;
  
  constructor(public translateService: TranslateService){}

  ngOnInit(): void {
      this.translateService.get('main.terms.steps').subscribe(res => {
        this.stepIndexes = Object.keys(res).length - 1;
      })
  }
}
