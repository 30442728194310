import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MAT_DATE_FORMATS,
  MatDateFormats,
  MatOptionModule,
} from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { ObjectManagement } from '../../models/object-management';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  AngularYandexMapsModule,
  YaReadyEvent,
  YaEvent,
  YaMapComponent,
} from 'angular8-yandex-maps';
import { DragAndDropDirective } from '../../directives/drag-and-drop.directive';
import { DragNDropComponent } from '../drag-n-drop/drag-n-drop.component';
import { ManagementService } from '../../services/management.service';
import { PaymentService } from '../../services/payment.service';
import { CompletedPayment } from '../../models/completed-payment';
import { MatSnackBar } from '@angular/material/snack-bar';

export const CATEGORIES = [
  { id: 1, name: 'Объект' },
  { id: 2, name: 'Человек' },
  { id: 3, name: 'Локация' },
];

export const SUBSCRIPTION_LEVELS = [
  { id: 1, name: 'Уровень 1' },
  { id: 2, name: 'Уровень 2' },
  { id: 3, name: 'Уровень 3' },
];

@Component({
  selector: 'app-management-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    AngularYandexMapsModule,
    DragAndDropDirective,
    DragNDropComponent,
  ],
  templateUrl: './management-dialog.component.html',
  styleUrl: './management-dialog.component.scss',
})
export class ManagementDialogComponent implements OnInit {
  objectForm: FormGroup;
  categories = CATEGORIES;
  subscriptionLevels = SUBSCRIPTION_LEVELS;
  langs: string[] = ['ru', 'by'];
  coordinates: number[];
  objectPayments: CompletedPayment[] = [];

  placemarkProperties: ymaps.IPlacemarkProperties = {
    hintContent: '',
    balloonContent: '',
  };
  placemarkOptions: ymaps.IPlacemarkOptions = {
    iconLayout: 'default#image',
    iconImageHref: 'assets/placemark.svg',
    iconImageSize: [32, 32],
  };

  progress = 0;

  center: number[] = [53.90374, 27.551823];
  zoom: number = 15;
  mapInstance: any; // Для хранения экземпляра карты
  files: any[] = [];

  @ViewChild('map') map: YaMapComponent;

  constructor(
    private paymentService: PaymentService,
    public dialogRef: MatDialogRef<ManagementDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { object: ObjectManagement | null; mode: string },
    private managementService: ManagementService,
    private notification: MatSnackBar
  ) {}

  ngOnInit(): void {
    if (this.data.mode === 'update') {
      this.initializeDialogOnUpdate();
    } else if (this.data.mode === 'create') {
      this.initializeDialogOnCreate();
    }
  }

  initializeDialogOnCreate() {
    this.objectForm = new FormGroup({
      name: new FormControl('', Validators.required),
      categoryId: new FormControl('', Validators.required),
      language: new FormControl('', Validators.required),
      published: new FormControl(false),
      description: new FormControl('', Validators.required),
      paymentDetails: new FormControl(''),
      latitude: new FormControl(this.center[0]),
      longitude: new FormControl(this.center[1]),
      approved: new FormControl(false),
      expirationDate: new FormControl(new Date(), Validators.required),
      subscriptionLevel: new FormControl(this.subscriptionLevels[0].id),
    });
  }

  initializeDialogOnUpdate() {
    this.center = [this.data.object.latitude, this.data.object.longitude];
    this.objectForm = new FormGroup({
      name: new FormControl(this.data.object.name, Validators.required),
      categoryId: new FormControl(
        this.data.object.categoryId,
        Validators.required
      ),
      language: new FormControl(this.data.object.language, Validators.required),
      published: new FormControl(this.data.object.published),
      description: new FormControl(
        this.data.object.description,
        Validators.required
      ),
      paymentDetails: new FormControl(this.data.object.paymentDetails),
      latitude: new FormControl(this.data.object.latitude),
      longitude: new FormControl(this.data.object.longitude),
      approved: new FormControl(this.data.object.approved),
      expirationDate: new FormControl(
        this.data.object.expirationDate,
        Validators.required
      ),
      subscriptionLevel: new FormControl(
        this.data.object.subscriptionLevel | this.subscriptionLevels[0].id
      ),
    });

    this.paymentService
      .getObjectPayments(this.data.object.uuid)
      .subscribe((payments: CompletedPayment[]) => {
        this.objectPayments = payments;
      });

    this.managementService
      .getObjectFiles(this.data.object.uuid)
      .subscribe((loadedFiles) => {
        this.files = loadedFiles;
      });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onUpdateConfirm(): void {
    if (this.objectForm.valid) {
      let objectFormValues = { ...this.objectForm.value };
      if (!this.objectForm.value.published) {
        delete objectFormValues['subscriptionLevel'];
      } else {
        objectFormValues.subscriptionLevel = Number(
          objectFormValues.subscriptionLevel
        );
      }
      objectFormValues.categoryId = Number(objectFormValues.categoryId);
      this.dialogRef.close({
        object: objectFormValues,
        files: this.files,
        mode: 'update',
      });
    } else {
      this.processIncorrectFields();
    }
  }

  processIncorrectFields() {
    const invalid = [];
    const controls = this.objectForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    this.notification.open(`Incorrect fields ${invalid.join(', ')}`, 'OK', {
      duration: 5000,
    });
  }

  onCreateConfirm(): void {
    if (this.objectForm.valid) {
      let objectFormValues = { ...this.objectForm.value };
      if (!this.objectForm.value.published) {
        delete objectFormValues['subscriptionLevel'];
      } else {
        objectFormValues.subscriptionLevel = Number(
          objectFormValues.subscriptionLevel
        );
      }
      objectFormValues.categoryId = Number(objectFormValues.categoryId);
      this.dialogRef.close({
        object: objectFormValues,
        files: this.files,
        mode: 'create',
      });
    } else {
      this.processIncorrectFields();
    }
  }

  onMapReady(event: YaReadyEvent) {
    this.mapInstance = event.target;
    this.mapInstance.events.add('click', (e: any) => {
      this.onMapClick(e);
    });
  }

  onMapClick(event: any) {
    const coords = event.get('coords');
    this.objectForm.value.latitude = coords[0];
    this.objectForm.value.longitude = coords[1];
    this.updatePlacemark();
  }

  updatePlacemark() {
    if (this.mapInstance) {
      this.mapInstance.geoObjects.removeAll(); // Удаляем все объекты с карты
      const placemark = new (window as any).ymaps.Placemark(
        [this.objectForm.value.latitude, this.objectForm.value.longitude],
        this.placemarkProperties,
        this.placemarkOptions
      );
      this.mapInstance.geoObjects.add(placemark); // Добавляем новый маркер
    }
  }

  sortByDate(a, b) {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  }
}
